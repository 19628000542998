<template>
  <b-card-code no-body title="User Activity Log History">

    <div class="row mb-1" style="margin-left: 10px; margin-right: 10px;">

      <div class="col-md-4" style="padding-top: 2px;">
        <v-select
          v-model="filter.user_id"
          :options="users"
          :reduce="option => option.id"
          @search="onSearchUser"
          placeholder="Select user..."
        >
          <template slot="no-options">
            Type user name, code, or email..
          </template>
        </v-select>
      </div>

      <div class="col-md-3">
        <date-picker v-model="filter.date" value-type="YYYY-MM-DD" placeholder="Select date"></date-picker>
      </div>

      <div class="col-md-5 text-right">
          <input type="text" v-model="filter.description" placeholder="Search description" class="form-control form-control-sm float-right" style="width: 200px;">
      </div>

    </div>

    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>#</th>
            <!-- <th>User Type</th> -->
            <th>User</th>
            <th>Description</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="4" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.id">
            <td>{{ item.id }}</td>
            <!-- <td class="text-capitalize">{{ item.type }}</td> -->
            <td>{{ item.user.email }}</td>
            <td>
              <small>{{ item.description }}</small>
            </td>
            <td>{{ item.time }}</td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="4" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title () {
    return `User Activity Log`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    DatePicker,
    vSelect
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function() {    
        this.getData();                                                                                                                                                                        
      }, 300),
      deep: true
    },
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        user_type: '',
        user_id: '',
        date: '',
        description: '',
      },
      users: [],
      isLoading: false,
    }
  },
  created() {
    this.getData();
    this.executeSearch('')
  },
  methods: {
    executeSearch(query){
      var vm = this
      this.$http.get('/admin/users?user_type=admin&per_page=200&sort_by_register=desc&keyword=' + encodeURI(query), { useCache: true })
      .then(response => {
        vm.users = [];
        response.data.data.data.forEach(function(user){
          vm.users.push({
            'id': user.id,
            'label': user.email
          })
        });

      })
    },
    onSearchUser(search, loading) {
      if(search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(encodeURI(search))
      loading(false);
    }, 300),
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/activity-log', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
  }
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>